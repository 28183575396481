.app--confirmation-mainContainer {
    padding-top: 1rem;
    height: 100vh;
    width: 600px;
    margin: auto;
    background-color: black;
    font-size: 1.25rem;
}

.confirmation-title {
    width: 400px;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: var(--color-golden)
}

.confirmationTable-container {
    color: white;
    border: 1px white solid;
    border-collapse: collapse;
}
.confirmationTable-container th,
td {
    color: white;
    border: 1px white solid;
}

.confirmationItem-quantity {
    text-align: center;
}

.confirmationItem-price {
    text-align: center;
}

.confirmationTotals-table {
    display: table-footer-group;
    vertical-align: middle;
    border-color: inherit;
    text-indent: initial;
    border-spacing: 2px;
}

.totals-label {
    border-collapse: collapse;
    text-align: right;
    padding-right: 10px;
    border-top: none;
    border-bottom: none;
}

.totals-label-last {
    border-collapse: collapse;
    text-align: right;
    padding-right: 10px;
    border-top: none;
}

.totals-value {
    border-collapse: collapse;
    text-align: center;
    border-top: none;
    border-bottom: none;
}
.totals-value-last {
    border-collapse: collapse;
    text-align: center;
    border-top: none;
}

.confirmation-title {
    font-size: 2rem;
    padding-bottom: 0.5rem;
}

.customer-info {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    gap: 0.25rem;
    padding-bottom: 8rem;
    margin-top: 1rem;
}

@media screen and (max-width: 650px) {
    .app--confirmation-mainContainer {
        font-size: 0.75rem;
        width: 100%;
    }
    .confirmationTable-container th,
    td {
        color: white;
        border: 1px white solid;
        padding: 0.2rem;
    }
}
