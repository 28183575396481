.app--aboutus {
    position: relative;
}

.app--aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app--aboutus-overlay img {
    width: 800px;
    height: 850px;
    z-index: 0;
}

.app--aboutus-content {
    width: 100%;
    z-index: 2;
}

.app--aboutus-content-about {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
}

.app--aboutus-content-history {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: right;
}

.app--aboutus-content-about p,
.app--aboutus-content-history p {
    margin: 2rem 0;
    color: var(--color-grey);
}

.app--aboutus-content-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.app--aboutus-content-image img {
    width: 75%;
}

.app--aboutus-content-knife {
    margin: 2rem 4rem;
}

.app--aboutus-content-knife img {
    height: 910px;
}

@media screen and (min-width: 2000px) {
    .app--aboutus-content-knife img {
        height: 1110px;
    }

    .app--aboutus-content-about p,
    .app--aboutus-content-history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 1150px) {
    .app--aboutus-content {
        flex-direction: column;
    }

    .app--aboutus-content-knife {
        margin: 4rem 0;
    }

    .app--aboutus-overlay img {
        height: 640px;
    }

    .app--aboutus-content-image img {
        width: 500px;
    }
}

@media screen and (max-width: 650px) {
    .app--aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
    .app--aboutus-content-knife img {
        height: 550px;
    }
    .app--aboutus-content-image img {
        width: 100%;
    }
}
