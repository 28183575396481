.menu--one-page {
    width: 50%;
}
.menu--two-page {
    width: 90%;
}

.menu--page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}
