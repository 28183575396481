.app--onlineOrderPage {
    width: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    padding-bottom: 6rem;
}

.app--footerOverlay-black {
    height: 25%;
    background: var(--color-black);
}

.app--onlineOrderBackground-img {
    height: 100%;
}

.app--onlineOrder-mainContainer {
    padding-top: 1rem;
    height: 100vh;
}

.app--onlineOrder-content {
    background-color: var(--color-black);
    color: var(--color-white);
    border-radius: 0.25rem;
    display: flex;
}

.error-message {
    font-size: 2rem;
    justify-content: center;
    margin: auto;
}

.app--onlineOrder-divider {
    width: 1px;
    background-color: var(--color-golden);
}

.app--scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

.app--scrollable::-webkit-scrollbar {
    width: 0 !important;
}

.app--onlineOrder-categoriesContainer {
    height: 100vh;
    width: 100%;
}

.app--onlineOrder-categories {
    height: 100vh;
}

.app--onlineOrder-cartContainer {
    min-width: 350px;
    height: 100vh;
}

.onlineOrder-categoriesButton-smallScreen {
    display: none;
}

.onlineOrder-cartButton-smallScreen {
    display: none;
}

.onlineOrder-buttonContainer-smallScreen {
    position: fixed;
    display: flex;
    width: auto;
    margin: 5px auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    bottom: 0;
}

/* CSS */
.onlineOrder-floatingButton {
    appearance: none;
    border: 2px solid #1a1a1a;
    border-radius: 15px;
    background-color: var(--color-golden);
    box-sizing: border-box;
    color: var(--color-black);
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
}

.onlineOrder-floatingButton:disabled {
    pointer-events: none;
}

.onlineOrder-floatingButton:hover {
    color: #fff;
    background-color: #1a1a1a;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.onlineOrder-floatingButton:active {
    box-shadow: none;
    transform: translateY(0);
}

@media screen and (min-width: 2000px) {
    .app--navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app--onlineOrder-cartContainer {
        display: none;
    }
    .onlineOrder-cartButton-smallScreen {
        display: flex;
        margin: auto;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app--onlineOrder-categories {
        display: none;
    }
    .app--onlineOrder-mainContainer {
        padding: 0;
    }
    .onlineOrder-categoriesButton-smallScreen {
        display: flex;
        margin: auto;
        width: 50%;
    }
    .onlineOrder-cartButton-smallScreen {
        width: 50%;
    }
    .onlineOrder-buttonContainer-smallScreen {
        width: 300px;
    }
}

.app--search-input {
    flex-direction: row;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: var(--color-black);
}

.app--search-input input {
    width: 100%;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
    font-size: 1.25rem;
}

.cart-smallscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}

.cart-smallscreen-overlay .overlay--close {
    font-size: 27px;
    color: var(--color-golden);
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 6;
}
