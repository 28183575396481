.cart--title {
    margin: 1rem auto;
    border-bottom: 1px solid var(--color-golden);
    flex-direction: row;
    position: sticky;
    top: 0;
    background-color: var(--color-black);
    width: 100%;
    background: var(--color-black);
    padding: 1rem;
}

.cart-title-text {
    margin: auto;
    width: 3rem;
    color: var(--color-golden);
}

.app--cartViewContainer {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    min-width: 350px;
}

.app--emptyCartLabel {
    width: 20rem;
}

.checkout-button {
    margin: 1rem;
}
