.app--foodItem-quantitySelectorContainer {
    margin: auto;
    display: flex;
    height: 40%;
}

.app--foodItem-quantitySelectorButton {
    font-size: 1.5rem;
}

.foodItem-quantityValue {
    padding-right: 2px;
}

.foodItem-mathIcons {
    margin-top: 3px;
}

@media screen and (max-width: 650px) {
    .app--foodItem-quantitySelectorButton {
        font-size: 1.25rem;
    }
}
