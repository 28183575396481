.scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-15 19:9:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scale-in-center {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

.modifiers-window-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    z-index: 5;
    background-color: rgba(70, 69, 69, 0.5);
    display: flex;
    justify-content: center;
}

.modifiers-window {
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--color-black);
    padding: 2rem;
    border: 1px solid var(--color-golden);
    border-radius: 4px;
    position: relative;
}

.modifier--close {
    font-size: 27px;
    color: var(--color-golden);
    position: absolute;
    top: 10px;
    right: 10px;
}

.modifierMessage-entry-container {
    height: 100%;
}

.horizontal-divider {
    background-color: var(--color-golden);
    height: 1px;
    margin-top: 0.5rem;
}

.modifier-message-entry {
    width: 25rem;
    height: 15rem;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
    font-size: 1.25rem;
    margin: 0;
}

.modifierTitle-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 650px) {
    .modifier-message-entry {
        width: 100%;
    }
    .modifiers-window {
        width: 85%;
    }
}
