.cartItem--title {
    display: flex;
    justify-content: space-between;
}

.cartItem-modifier-container {
    display: flex;
    justify-content: space-between;
}

.cartItem-modifier-name {
    word-break: break-word;
    max-width: 250px;
    display: flex;
}

.cartItem--container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(128, 128, 128, 0.515);
    margin-bottom: 1rem;
}

.cartItem--price {
    justify-self: flex-end;
}

.cartItem--quantityName {
    display: flex;
}

.p--quantity {
    width: 2rem;
    align-self: center;
}

.cartItem--interactableContainer {
    align-self: flex-end;
    display: flex;
    gap: 0.5rem;
}

.cartItem--interactableItem {
    transition: 0.5s ease;
    color: var(--color-golden);
}

.cartItem--interactableItem:hover {
    border-bottom: 1px solid var(--color-golden);
    cursor: pointer;
}
