.checkout-fields-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.checkout-input-container {
    display: flex;
    flex-direction: column;
}

.checkout-row-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.app--checkout-input {
    flex-direction: row;
    background-color: var(--color-black);
}

.app--checkout-input input {
    width: 100%;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    color: var(--color-white);

    padding: 0.75rem 1rem;
    background: var(--color-black);
    font-size: 1.25rem;
}

.order-modifier-entry-container {
    height: 100%;
}

.order-modifier-entry {
    width: 100%;
    height: 15rem;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
    font-size: 1.25rem;
    margin: 0;
}

.order-submit-button {
    height: 4rem;
}

.checkout-footer-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: auto;
}

.checkout-footerItem-container {
    display: flex;
    flex-direction: column;
}

.order-total-container {
    text-align: center;
}

@media screen and (min-width: 2000px) {
}

@media screen and (max-width: 1150px) {
}

@media screen and (max-width: 650px) {
    .checkout-row-container {
        display: flex;
        flex-direction: column;
        gap: 0rem;
    }
    .checkout-fields-container {
        gap: 0rem;
    }

    .checkout-footer-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .order-total-label {
        text-align: center;
    }
}

.checkout-window-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    z-index: 6;
    background-color: rgba(70, 69, 69, 0.5);
    display: flex;
    justify-content: center;
}
