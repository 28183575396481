.app--footerOverlay {
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    position: absolute;
}
.app--footerOverlay-black {
    height: 25%;
    background: var(--color-black);
}
.app--footerOverlay-img {
    height: 75%;
}
