.app--gallery {
    display: flex;
    flex-direction: row;
    /* background: var(--color-black); */
    padding: 4rem 0 4rem 6rem;
}

.app--gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 500px;
    padding-right: 2rem;
}

.app--gallery-content button {
    margin-top: 1rem;
}

.app--gallery-images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app--gallery-images-container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.app--gallery-images-container::-webkit-scrollbar {
    display: none;
}

.app--gallery-images-card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery--image-icon {
    position: absolute;
    color: #fff;
    font-size: 2rem;
    opacity: 0.5;
    transition: 0.5s ease;
    cursor: pointer;
}

.app--gallery-images-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: 0.5s ease;
}

.app--gallery-images-card:hover img {
    /* opacity: 0.35; */
}

.app--gallery-images-card:hover .gallery--image-icon {
    opacity: 1;
}

.app--gallery-images-arrow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    position: absolute;
    bottom: 5%;
}

.gallery--arrow-icon {
    color: var(--color-golden);
    cursor: pointer;
    background: var(--color-black);
    font-size: 2em;
    border-radius: 5px;
    user-select: none;
}

.gallery--arrow-icon:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app--gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app--gallery-images-card {
        min-width: 400px;
        height: 550px;
    }
}

@media screen and (max-width: 1150px) {
    .app--gallery {
        flex-direction: column;
    }
    .app--gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app--gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app--gallery {
        padding: 4rem 0 4rem 2rem;
    }

    .app--gallery-content {
        min-width: 100%;
    }

    .app--gallery-images-card {
        min-width: 240px;
        height: 320px;
    }
}
