.modification-entry {
    display: flex;
    justify-content: space-between;
}

.modification-name {
    align-self: flex-start;
}
.modification-price {
    align-self: flex-end;
}
