.app--navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    padding: 1rem 4rem;
}

.app--navbar-logo {
    padding-top: 0.5rem;
    display: flex;
    justify-content: start;
    align-items: center;
}

.app--navbar-logo img {
    width: 150px;
}

.app--navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app--navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app--navbar-links li:hover {
    color: var(--color-grey);
}

.app--navbar-order {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app--navbar-order a {
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
}

.app--navbar-order a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app--navbar-order div {
    width: 1px;
    height: 30px;
    background-color: var(--color-grey);
}

.app--navbar-smallscreen {
    display: none;
}

.app--navbar-smallerscreen {
    display: none;
}

.app--navbar-smallscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--color-black);
    transition: 0.5s ease;

    flex-direction: column;
    z-index: 5;
}

.app--navbar-smallscreen-overlay .overlay--close {
    font-size: 27px;
    color: var(--color-golden);
    position: absolute;
    top: 20px;
    right: 20px;
}

.app--navbar-smallscreen-links {
    list-style: none;
}

.app--navbar-smallscreen-links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app--navbar-smallscreen-links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app--navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app--navbar-links {
        display: none;
    }
    .app--navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app--navbar-logo img {
        width: 110px;
    }
    .app--navbar {
        padding: 1rem;
    }
    .app--navbar-order {
        display: none;
    }
    .app--navbar-smallerscreen {
        display: flex;
        justify-content: center;
    }
}
