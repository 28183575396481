.a--foodItemDescription {
    color: rgb(170, 170, 170);
}

.foodItem-description-container {
    width: 100%;
}

.foodItem--title {
    color: var(--color-golden);
}

.app--foodItemContainer {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-golden);
}

.app--foodItemImage {
    height: 8rem;
    width: 8rem;
    object-fit: contain;
}

.app--foodItem-interactable {
    display: flex;
    flex-direction: column;
}

.app--foodItem-price {
    display: flex;
    justify-content: center;
    font-size: larger;
    font-style: italic;
}

.foodItem-priceValue {
    margin: 0px auto;
}

.app--foodItem-orderButton {
    border-radius: 0.5rem;
    width: 100%;
}

.app--foodItem-orderButtonCointainer {
    margin: 0;
}

.foodItem--interactable-smallScreen {
    display: none;
}

@media screen and (max-width: 1150px) {
    .app--foodItem-interactable {
        display: none;
    }
    .foodItem--interactable-smallScreen {
        display: flex;
        flex-direction: column;
    }
    .app--foodItem-orderButton {
        border-radius: 0.5rem;
        padding: 1px 1rem;
        line-height: none;
    }
}

@media screen and (max-width: 650px) {
    .app--onlineOrder-categories {
        display: none;
    }
    .app--onlineOrder-mainContainer {
        padding: 0;
    }
    .onlineOrder-categoriesButton-smallScreen {
        display: flex;
        margin: auto;
        width: 50%;
    }
    .onlineOrder-cartButton-smallScreen {
        width: 50%;
    }
    .onlineOrder-buttonContainer-smallScreen {
        width: 300px;
    }
}
