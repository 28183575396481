@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");

:root {
    --font-base: "Cormorant Upright", serif;
    --font-alt: "Open Sans", sans-serif;
    --category-fontSize: 1rem;

    --color-golden: #dcca87;
    --color-black: #0c0c0c;
    --color-gray: #545454;
    --color-crimson: #f5efdb;
    --color-grey: #aaaaaa;
    --color-white: #ffffff;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    color: unset;
    text-decoration: none;
}

.slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(25%);
        transform: translateY(25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(25%);
        transform: translateY(25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
