.app-category-item {
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
    padding: 0.5rem;
    font-size: var(--category-fontSize);
}

.app-category-item:hover {
    border-bottom: 1px solid var(--color-golden);
    cursor: pointer;
}

.app--categorySelectorContainer {
    display: flex;
    flex-direction: column;
    border-right: 2px solid var(--color-gold);
}
