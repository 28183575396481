.app--newsletter {
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
    background: var(--color-black);
}

.app--newsletter-heading {
    text-align: center;
}

.app--newsletter-input {
    flex-direction: column;
    margin-top: 1rem;
}

.app--newsletter-input input {
    width: 620px;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
    margin: 0 0 1rem 0;
}

.feedback-input-row {
    display: flex;
    gap: 0.5rem;
    width: 620px;
}

.app--newsletter-input button {
    width: max-content;
    margin-top: 0.5rem;
}

.feedback-body {
    height: 200px;
}

.feedback-body {
    width: 620px;
    height: 15rem;
    border: 1px solid var(--color-golden);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-base);
    color: var(--color-white);
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    background: var(--color-black);
    font-size: 1.25rem;
    margin: 0;
}

@media screen and (min-width: 2000px) {
    .app--newsletter-input input {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .app--newsletter-input {
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
    }

    .app--newsletter-input input {
        margin: 0 0 1rem 0;
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    .app--newsletter {
        padding: 2rem 0;
        border: none;
    }
    .app--newsletter-input input {
        width: 85%;
        margin: auto;
    }
    .app--newletter-heading {
        width: 85%;
        margin: auto;
        font-size: 32px;
    }
    .feedback-input-row {
        flex-direction: column;
        width: 100%;
    }
    .feedback-body {
        width: 85%;
    }
}

@media screen and (max-width: 300px) {
    .app--newsletter-heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
    .feedback-input-row {
        flex-direction: column;
    }
}
